<template>
	<div>
		<van-nav-bar
			title="接種記錄"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="order">
			<van-list
				v-if="hasData"
				@load="getList" 
				:offset="0" 
				class="list"
				v-model="loading"
				:finished="finished"
				:immediate-check="false"
			>
				<div v-for="(item, index) in lists" :key="item.id" class="item">
					<div class="item_header">
						<span class="item_title">新冠疫苗--復必泰二價</span>
						<span class="item_status">已完成</span>
					</div>
					<div class="item_cell">
						<span class="item_cell_lab">姓名</span>
						<span class="item_cell_val">{{item.name}}</span>
					</div>
					<div class="item_cell">
						<span class="item_cell_lab">手機號碼</span>
						<span class="item_cell_val">{{item.mobile}}</span>
					</div>
					<div class="item_cell">
						<span class="item_cell_lab">接種地點</span>
						<span class="item_cell_val">{{item.appt_addr}}</span>
					</div>
					<div class="item_cell">
						<span class="item_cell_lab">接種時間</span>
						<span class="item_cell_val">{{item.appt_time}}</span>
					</div>
				</div>
			</van-list>
			<div v-else class="no-data qjc-fts-32">
				<van-image
					width="5.04rem"
					height="2.9rem"
					:src="require('@/assets/images/history_null.png')"
				/>
				<p class="qjc-fts-28">您還沒有接種記錄</p>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesRecord',
		data (){
			return {
				height: 0,
				hasData: false,
				finished: false,
				loading: false,
				lists: [],
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			this.height = (list_height/100)/ratio * 2; //历史记录 高度
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			getList() {
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.order{
		width: 100%;
		min-height: 100vh;
		padding: 1.18rem 0.3rem 0.3rem;
		background: #F5F6FA;
	}
	
	.appoint {
	    width: 100%;
	    min-height: 100vh;
	    background-color: #F5F6FA;
	}
	.list {
	    width: 100%;
	}
	.item {
	    width: 100%;
	    padding: 0.30rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    margin-bottom: 0.30rem;
	}
	.item_header {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    margin-bottom: 0.40rem;
	}
	.item_title {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.40rem;
	}
	.item_status {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #6681FA;
	    line-height: 0.40rem;
	}
	.item_cell {
	    width: 100%;
	    display: flex;
	    /* align-items: center; */
	    justify-content: space-between;
	    line-height: 0.48rem;
	    margin-bottom: 0.26rem;
	}
	.item_cell_lab {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #8C919A;
	    line-height: 0.40rem;
	}
	.item_cell_val {
	    flex: 1;
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.40rem;
	    text-align: right;
	}
	.popup {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.74rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	.popup_box {
	    text-align: center;
	}
	.popup_tips {
	    width: 2.20rem;
	    height: 2.20rem;
	    margin-top: -0.72rem;
	}
	.popup_text {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #777F8F;
	    line-height: 0.44rem;
	    margin-top: 0.86rem;
	    /* text-align: center; */
	}
	.popup_btn {
	    width: 43% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.80rem;
	}
	
	.popup_close {
	    position: absolute;
	    bottom: -1.36rem;
	    left: 44%;
	    width: 0.84rem;
	    height: 0.84rem;
	}
	.close_icon {
	    width: 0.84rem;
	    height: 0.84rem;
	}
	
	.popup_btn_cell {
	    width: 100%;
	    display: flex;
	    align-items: center;
	}
	.popup_btn_cal {
	    width: 43% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #6681FA;
	    background-color: #fff;
	    border-radius: 0.12rem;
	    margin-top: 0.80rem;
	    border: 0.01rem solid #6681FA;
	}
	
	.success_box {
	    width: 100%;
	    text-align: center;
	    margin-top: 0.74rem;
	}
	.success_icon {
	    width: 0.88rem;
	    height: 0.88rem;
	}
	.success_text {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #000000;
	    line-height: 0.44rem;
	    text-align: center;
	    margin-top: 0.50rem;
	}
	.success_btn {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #8C919A;
	    line-height: 0.44rem;
	    margin-top: 0.30rem;
	    text-align: center;
	    text-decoration: underline;
	}
	
	
	.no_data {
	    width: 100%;
	    height: 100vh;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.no_data_img {
	    width: 2.40rem;
	    height: 2.40rem;
	}
	.no_data_text {
	    font-size: 0.32rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #777F8F;
	    text-align: center;
	}
	.drawback {
	    font-size: 0.36rem;
	}
	
	.deduct_price {
	    position: relative;
	    color: #32363E;
	}
	.deduct_price::before {
	    content: '￥';
	    position: absolute;
	    left: -0.20rem;
	    top: 0.04rem;
	    font-size: 0.22rem;
	    font-family: MiSans-Demibold, MiSans;
	    font-weight: 500;
	    color: #32363E;
	}
	
	.del_btn {
	    width: 1.92rem !important;
	    height: 0.60rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    padding: 0;
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #6681FA;
	    background-color: #fff;
	    border-radius: 0.54rem;
	    border: 0.02rem solid #6681FA;
	    margin: 0 0 0 0.16rem !important;
	}
	
	.del_text {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #777F8F;
	    line-height: 0.44rem;
	    margin-top: 0.86rem;
	}
	.is_bototm {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #8C919A;
	    text-align: center;
	}
	
	.copy_icon {
	    width: 0.24rem;
	    height: 0.24rem;
	}
	.no-data{
		color: #777F8F;
		margin-top: 2.5rem;
		
		p{
			margin-top: 0.6rem;
			color: #9A9FB4;
		}
	}
</style>
